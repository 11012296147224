html,
body {
    font-size: 16px;
    color: #333333;
    background-color: #eff1f2;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    height: 100%;
}
#root {
    height: 100%;
}
body.ReactModal__Body--open {
    overflow: hidden;
}
:focus {
    outline: none;
}
a,
a:hover {
    color: #00aeef;
}
hr {
    border-top: 4px solid rgba(0, 0, 0, 0.1);
}
button:focus,
a:focus {
    outline: 0px none;
}

.whole-container {
    padding: 25px 25px 0px 25px;
    width: 100%;
}

.rnc__base {
    top: 0px;
    left: 0px;
}

.form-group.with-time-separator {
    position: relative;
}
.form-group.with-time-separator .time-separator {
    position: absolute;
    bottom: 5px;
    right: -18px;
    font-weight: bold;
    font-size: 25px;
}
.form-group.with-time-separator .time-separator.milliseconds {
    bottom: 2px;
}

#login {
    background-repeat: no-repeat;
    background-color: #eff1f2;
}
#login .logo {
    height: 278px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
#login .logo .version {
    color: #603813;
    text-align: center;
    font-size: 0.75rem;
}
#login .panel {
    background-color: #fff;
    width: 484px;
    text-align: center;
    position: relative;
    margin: 0 auto;
    padding: 4px;
    box-shadow: 0px 0px 5px #cbcbcb;
}
#login .inner-panel {
    border: 1px solid #eeeeee;
    padding: 35px;
}

#login .panel a {
    font-size: 0.75rem;
}
#login .copy-tag {
    position: absolute;
    font-size: 0.75rem;
    bottom: -30px;
    left: 0px;
    color: #bbbbbb;
    width: 100%;
    text-align: center;
}
#login .input-group-prepend img {
    width: 30px;
}
#header {
    background-color: #ffffff;
    width: 100%;
    z-index: 1;
    box-shadow: 0px 0px 5px #cccccc;
}
#header ul {
    list-style-type: none;
}
#header ul li {
    border-right: 1px solid rgba(0, 0, 0, 0.125);
}
#header ul li a,
#header ul li button {
    padding: 10px;
    display: block;
    line-height: 25px;
    font-size: 0.75rem;
    color: #00aeef;
}
#header ul li button {
    border: 0px none;
    cursor: pointer;
    background-color: #ffffff;
}
#header ul li a:hover,
#header ul li button:hover,
#header ul li a.active,
.sideBar ul li a:hover,
.sideBar ul li a.active,
.sideBar ul li button:hover {
    color: #fff;
    background-color: #00aeef;
    text-decoration: none;
}
#header ul li.user {
    border-left: 1px solid rgba(0, 0, 0, 0.125);
}
#header ul li.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 0px 15px;
    width: 220px;
}
#header ul li.logo img {
    height: 25px;
}
#header ul li img {
    max-width: 100%;
    height: 23px;
}
#header .glyphicons-door {
    font-size: 1.06rem;
}
.sideBar {
    height: 100%;
    width: 220px;
    background-color: #10121c;
    z-index: 1;
    transition: 0.5s all;
}
.sideBar ul {
    list-style-type: none;
}
.sideBar > ul {
    width: inherit;
}
.sideBar ul li img {
    max-width: 100%;
    height: 23px;
}
.sideBar ul li a {
    padding: 10px;
    display: block;
    line-height: 25px;
    font-size: 0.75rem;
    color: #00aeef;
}
.sideBar span {
    display: none;
}
.sideBar ul li a,
.sideBar ul li button {
    text-align: right;
    padding-right: 13px;
    transition: 0.5s all;
}
.sideBar ul li button {
    width: 100%;
    background-color: #10121c;
}
.sideBar.open ul li a,
.sideBar.open ul li button {
    display: flex;
    align-items: center;
    text-align: left;
}
.sideBar.open ul li a i,
.sideBar.open ul li button i {
    font-size: 23px;
    width: 35px;
    height: 23px;
    text-align: center;
    color: #fff;
}
.sideBar ul li button,
.sideBar ul li a {
    color: #dddddd;
    padding-left: 0px;
}
.sideBar.open {
    left: 0px;
}
.sideBar.open span {
    display: inline-block;
}
.sideBar ul {
    margin: 0px;
    padding: 0px;
    background-color: #10121c;
}
.sideBar ul li {
    border-right: 0px none;
    border-left: 0px none;
    border-bottom: 1px solid #2d3144;
    position: relative;
}
.sideBar ul li ul {
    position: absolute;
    right: -220px;
    top: -1px;
    width: 220px;
}
.sideBar.open ul li ul {
    position: relative;
    right: auto;
    top: auto;
}

.sideBar ul li ul.hidden {
    display: none;
}
.sideBar ul li ul li.last {
    border-bottom: 0px none;
}
.sideBar ul li ul li a {
    border-left: 2px solid #10121c;
    padding: 5px;
    font-size: 0.7rem;
    background-color: #002837;
}
.sideBar ul li ul li a:hover {
    background-color: #00aeef;
    border-left: 2px solid #002837;
}
.sideBar ul li ul li a.active {
    color: #fff;
    background-color: #00aeef;
    border-left: 2px solid #002837;
}
#header li.menuMobile {
    display: none;
}
#layout {
    height: 100%;
    display: flex;
    flex-direction: column;
}
#notification {
    position: fixed;
    width: 300px;
    bottom: 20px;
    right: 20px;
    z-index: 99999;
}
#notification .notify {
    margin-top: 15px;
    padding: 10px;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.125);
}
#notification .notify .alert {
    margin-bottom: 0px;
}
.date-w-100 .react-datepicker-wrapper {
    width: 100%;
}
.custom-file,
.custom-file-input,
.custom-file-label {
    height: 46px;
}
.custom-file-label {
    display: flex;
    align-items: center;
}
.custom-file-label::after {
    height: 100%;
    display: flex;
    align-items: center;
}
.athlete-img {
    max-height: 180px;
}
.competition-img {
    max-height: 100px;
}
.organiser-img {
    max-height: 180px;
}
.club-img {
    max-height: 180px;
}
.federation-img {
    max-height: 180px;
}
.country-img {
    max-height: 180px;
    border: 1px solid;
}
.data-list {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
}
.data-list-item-content {
    display: flex;
    border-bottom: 1px solid #ddd;
    justify-content: center;
    align-items: center;
}
.data-list-item.header {
    border-bottom: 4px solid #ddd;
}
.data-list-item.header div {
    font-weight: bold;
    font-size: 0.85rem;
}
.data-list-item-content div,
.data-list-item-content .item {
    padding: 10px;
    flex: 1;
    font-size: 0.85rem;
    margin: 0px;
}
.data-list-item-content div div {
    padding: 0px;
}
.data-list-item-content.categories div {
    flex: 1;
}
.data-list-item-content.links div {
    flex: none;
    width: 20%;
}

.data-list-item-content.line-top {
    border-top: 4px solid #ddd;
}

.data-list-item-content .static {
    flex: 0 0 140px;
}
.data-list-item-content .static.small {
    flex: 0 0 80px;
}

.article-list-img {
    height: 25px;
}

.link-selector {
    height: 150px;
    background-color: #eeeeee;
    overflow-y: scroll;
}
.lang-select {
    width: 80px;
}
.badge-orange {
    background-color: #ff7700;
    color: #fff;
}
textarea.form-control,
input.form-control,
select.form-control,
select.form-control:focus,
select.form-control:active,
input.form-control:focus,
input.form-control:active,
.input-group-text,
.form-control:disabled,
.form-control[readonly] {
    background-color: #eeeeee;
    border: 0px none;
    color: #7d7d7d;
    box-shadow: none;
    font-size: 0.75rem;
    padding: 15px;
    height: 46px;
}
.form-control:disabled,
.form-control:disabled:active {
    background-color: #f7f6f6;
    color: #d2d1d1;
}
textarea.form-control-sm,
input.form-control-sm,
select.form-control-sm,
select.form-control-sm:focus,
select.form-control-sm:active,
input.form-control-sm:focus,
input.form-control-sm:active,
.input-group-text,
.form-control-sm:disabled,
.form-control-sm[readonly] {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}
.form-control-sm:disabled,
.form-control-sm:disabled:active {
    background-color: #f7f6f6;
    color: #d2d1d1;
}
textarea.form-control {
    height: 100px;
}
.edit-icon,
.remove-icon {
    height: 16px;
    cursor: pointer;
}
.content-icon,
.print-icon {
    height: 24px;
    cursor: pointer;
}
.move-icon {
    height: 18px;
}
label {
    font-size: 0.875rem;
}
label.input-label {
    font-weight: bold;
}
.input-group-text {
    font-size: 1rem;
}
.table th {
    font-size: 0.75rem;
    font-weight: 700;
    padding: 0.5rem;
}
.table td {
    font-size: 0.75rem;
    padding: 0.5rem;
}
.alert {
    font-size: 0.875rem;
}
.btn {
    font-size: 0.75rem;
    text-transform: uppercase;
    padding: 12px;
    font-weight: 700;
}
.btn-thiner {
    padding: 5px 12px;
}
.btn-primary {
    background-color: #8dc63f;
    border-color: #8dc63f;
}
.btn-primary:hover {
    background-color: #8dc63f;
    border-color: #8dc63f;
}

.btn-blue {
    background-color: #00aeef;
    border-color: #00aeef;
}
.btn-blue:hover {
    background-color: #0097cf;
    border-color: #0097cf;
}
.btn-delete-files {
    line-height: 10px;
    padding: 10px;
    height: 35px;
    border: 1px solid #ced4da;
}
.btn-delete-files:hover {
    border: 1px solid #ced4da;
}
.nav-tabs {
    margin-bottom: 20px;
}
.nav-link {
    padding: 1rem 1.5rem;
}
.nav-tabs .nav-link {
    border: 1px solid rgba(0, 0, 0, 0.125);
    font-weight: bold;
    color: #333;
    cursor: pointer;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: rgba(0, 0, 0, 0.125);
    background-color: #eee;
}

.nav-tabs .nav-link.disabled {
    color: #cccccc;
    cursor: not-allowed;
    pointer-events: initial;
}
.nav-tabs .nav-link.active {
    background-color: #00aeef;
    color: #fff;
}
.side-nav {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    max-width: 100%;
    background-color: #fff;
    /* border-right: 1px solid rgba(0,0,0,.125); */
    box-shadow: 2px 0px 8px rgba(0, 0, 0, 0.1);
}
.side-nav-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.side-nav-item .nav-link {
    padding: 1rem;
    color: #333;
}

.side-nav-item .nav-link:hover {
    background-color: #f7f7f7;
}
.side-nav-item .nav-link.active,
.side-nav-item .nav-link:active {
    color: #00aeef;
}
.side-nav-item-title {
    padding: 1rem;
    background-color: #00aeef;
    color: #fff;
    font-size: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: default;
    text-transform: uppercase;
    font-weight: bold;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.discipline-title {
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.athlete-search-icon {
    color: #ccc;
}
.search-result {
    position: absolute;
    top: 48px;
    left: 0px;
    right: 0px;
    z-index: 10;
    border: 1px solid #ccc;
    border-radius: 3px;
}
.search-result .item:first-child {
    border-top: none;
}
.search-result .item {
    border-top: 1px solid #ccc;
    cursor: pointer;
    padding: 10px;
    font-size: 12px;
    background-color: #fff;
}
.search-result .item:hover,
.search-result .item.selected {
    background-color: #eee;
}
.add-team .selected-athlete {
    padding: 10px;
    font-size: 12px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
    cursor: default;
}
.cm-content .container {
    max-width: 1440px;
}
.flex-none {
    flex: none !important;
}
.input-group-text {
    font-size: 14px;
    position: relative;
    height: 100%;
}
.discipline-detail {
    min-height: 300px;
}
.delete-checked {
    vertical-align: middle;
    margin-left: 4px;
}
.info-desc {
    font-size: 12px;
    font-style: italic;
}
.image-mirror {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}
.whole-loading {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 50px 0px;
}
.loading {
    text-align: center;
    font-size: 0.875rem;
}
.no-flex {
    flex: none !important;
}
.no-margin {
    margin: 0px !important;
}
.w-40 {
    width: 40px;
}
.fs-14 {
    font-size: 0.875rem;
}
.pl-15 {
    padding-left: 15px !important;
}
.p-15 {
    padding: 15px !important;
}
.bold {
    font-weight: bold;
}
.hide {
    display: none;
}
.badge.not-specified {
    color: #fff;
}
.maxwidth-100 {
    max-width: 100%;
}

.modal-lg,
.modal-xl {
    max-width: 1199px !important;
}
.modal-max {
    max-width: 100% !important;
    padding: 10px !important;
}
.custom-file-input {
    cursor: pointer;
    z-index: 100000;
}
.custom-file-label::after {
    content: "Vybrat" !important;
    background-color: #00aeef;
    color: #fff;
}

.cursor-pointer {
    cursor: pointer;
}
.ub-tooltip {
    z-index: 10000;
}
.ub-tooltip.max-width-600 {
    max-width: 60rem;
}

.ReactModal__Overlay {
    opacity: 0;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.8) !important;
    z-index: 1300 !important;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
    transition: opacity 150ms ease-out;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.ReactModal__Content.modal-dialog {
    border: none;
    background-color: transparent;
}

.ReactCrop__image {
    max-height: 100% !important;
}
.form-check-input {
    margin-top: 0.4rem;
}
.react-datepicker-popper {
    z-index: 10 !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.red {
    color: red !important;
}
.green {
    color: #8dc63f !important;
}

.spinner {
    margin: 10px auto;
    width: 50px;
    height: 40px;
    text-align: center;
    font-size: 10px;
}

.spinner > div {
    background-color: #00aeef;
    height: 100%;
    width: 6px;
    display: inline-block;
    margin-right: 2px;
    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.spinner .rect3 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

.spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
    0%,
    40%,
    100% {
        -webkit-transform: scaleY(0.4);
    }
    20% {
        -webkit-transform: scaleY(1);
    }
}

@keyframes sk-stretchdelay {
    0%,
    40%,
    100% {
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }
    20% {
        transform: scaleY(1);
        -webkit-transform: scaleY(1);
    }
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 0rem auto;
        padding: 1.75rem 0rem;
    }
}

.hp-1 {
    height: 1px !important;
}
.hp-2 {
    height: 2px !important;
}
.hp-3 {
    height: 3px !important;
}
.hp-4 {
    height: 4px !important;
}
.hp-5 {
    height: 5px !important;
}
.hp-6 {
    height: 6px !important;
}
.hp-7 {
    height: 7px !important;
}
.hp-8 {
    height: 8px !important;
}
.hp-9 {
    height: 9px !important;
}
.hp-10 {
    height: 10px !important;
}
.hp-11 {
    height: 11px !important;
}
.hp-12 {
    height: 12px !important;
}
.hp-13 {
    height: 13px !important;
}
.hp-14 {
    height: 14px !important;
}
.hp-15 {
    height: 15px !important;
}
.hp-16 {
    height: 16px !important;
}
.hp-17 {
    height: 17px !important;
}
.hp-18 {
    height: 18px !important;
}
.hp-19 {
    height: 19px !important;
}
.hp-20 {
    height: 20px !important;
}
.hp-21 {
    height: 21px !important;
}
.hp-22 {
    height: 22px !important;
}
.hp-23 {
    height: 23px !important;
}
.hp-24 {
    height: 24px !important;
}
.hp-25 {
    height: 25px !important;
}
.hp-26 {
    height: 26px !important;
}
.hp-27 {
    height: 27px !important;
}
.hp-28 {
    height: 28px !important;
}
.hp-29 {
    height: 29px !important;
}
.hp-30 {
    height: 30px !important;
}
.hp-31 {
    height: 31px !important;
}
.hp-32 {
    height: 32px !important;
}
.hp-33 {
    height: 33px !important;
}
.hp-34 {
    height: 34px !important;
}
.hp-35 {
    height: 35px !important;
}
.hp-36 {
    height: 36px !important;
}
.hp-37 {
    height: 37px !important;
}
.hp-38 {
    height: 38px !important;
}
.hp-39 {
    height: 39px !important;
}
.hp-40 {
    height: 40px !important;
}
.hp-41 {
    height: 41px !important;
}
.hp-42 {
    height: 42px !important;
}
.hp-43 {
    height: 43px !important;
}
.hp-44 {
    height: 44px !important;
}
.hp-45 {
    height: 45px !important;
}
.hp-46 {
    height: 46px !important;
}
.hp-47 {
    height: 47px !important;
}
.hp-48 {
    height: 48px !important;
}
.hp-49 {
    height: 49px !important;
}
.hp-50 {
    height: 50px !important;
}

.fs1 {
    font-size: 1px !important;
}
.fs2 {
    font-size: 2px !important;
}
.fs3 {
    font-size: 3px !important;
}
.fs4 {
    font-size: 4px !important;
}
.fs5 {
    font-size: 5px !important;
}
.fs6 {
    font-size: 6px !important;
}
.fs7 {
    font-size: 7px !important;
}
.fs8 {
    font-size: 8px !important;
}
.fs9 {
    font-size: 9px !important;
}
.fs10 {
    font-size: 10px !important;
}
.fs11 {
    font-size: 11px !important;
}
.fs12 {
    font-size: 12px !important;
}
.fs13 {
    font-size: 13px !important;
}
.fs14 {
    font-size: 14px !important;
}
.fs15 {
    font-size: 15px !important;
}
.fs16 {
    font-size: 16px !important;
}
.fs17 {
    font-size: 17px !important;
}
.fs18 {
    font-size: 18px !important;
}
.fs19 {
    font-size: 19px !important;
}
.fs20 {
    font-size: 20px !important;
}
.fs21 {
    font-size: 21px !important;
}
.fs22 {
    font-size: 22px !important;
}
.fs23 {
    font-size: 23px !important;
}
.fs24 {
    font-size: 24px !important;
}
.fs25 {
    font-size: 25px !important;
}
.fs26 {
    font-size: 26px !important;
}
.fs27 {
    font-size: 27px !important;
}
.fs28 {
    font-size: 28px !important;
}
.fs29 {
    font-size: 29px !important;
}
.fs30 {
    font-size: 30px !important;
}
.fs35 {
    font-size: 35px !important;
}
.fs40 {
    font-size: 40px !important;
}
.fs45 {
    font-size: 45px !important;
}
.fs50 {
    font-size: 50px !important;
}
