.tox.tox-tinymce-inline {
    z-index: 1300;
}

.content-manager .cm-header {
    border-bottom: 1px solid #dee2e6;
    padding: 10px;
}
.content-manager .cm-content {
    width: 100%;
}

.content-manager section {
    position: relative;
    background-position: center center;
    background-size: cover;
}
.content-manager section.cm-min-height {
    min-height: 400px;
}
.content-manager section div.cols-element {
    min-height: 200px;
}
.content-manager section .cm-menu {
    position: absolute;
    bottom: 5px;
    right: 2px;
    font-size: 0.75rem;
    z-index: 21;
}
.content-manager section div .cm-menu {
    top: 6px;
    height: 32px;
    z-index: 22;
}
.content-manager section .cm-menu .item-container {
    background-color: #fff;
    padding: 8px 5px 5px 5px;
    height: calc(1.75rem + 2px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    border-radius: 0.25rem;
}
.content-manager section .cm-menu .item-container.highlight {
    background-color: #ddd;
}
.content-manager section .cm-menu .item-container img {
    vertical-align: initial;
}
.content-manager section .cm-menu .settings {
    position: absolute;
    right: -2px;
    top: 34px;
    min-width: 200px;
    text-align: right;
    z-index: 50;
    background-color: #fff;
    padding: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
}
.content-manager section .cm-menu .settings.settings-banner {
    min-width: 400px;
}
.content-manager section .cm-menu .settings.settings-bordered-info-block {
    min-width: 400px;
}
.content-manager section .cm-menu .settings.settings-social {
    min-width: 400px;
}
.content-manager section .cm-menu .settings.settings-articles {
    min-width: 400px;
}
.content-manager section .cm-menu .settings.settings-work-in-numbers {
    min-width: 250px;
}

.content-manager section .cm-add-section {
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    height: 50px;
    width: 50px;
    cursor: pointer;
    border-radius: 25px;
    z-index: 30;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.3);
    background-color: #fff;
}
.content-manager section .cm-menu .input-group-text {
    font-size: 0.75rem;
    padding: 0.275rem 0.75rem;
    background-color: #fff;
    border: 0px none;
    color: #00aeef;
    border: 1px solid #eeeeee;
}
.content-manager section .cm-menu button {
    color: #8dc63f;
    text-transform: uppercase;
    font-weight: bold;
    border: 0px none;
    padding: 0.275rem 0.75rem;
    height: calc(1.75rem + 2px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    border-radius: 0.25rem;
    background-color: #fff;
}
.content-manager section .cm-menu button.blue {
    color: #00aeef;
    font-weight: normal;
    text-transform: capitalize;
}
.content-manager section .cm-menu .custom-file-label {
    font-size: 0.75rem;
    height: calc(1.75rem + 2px);
    padding: 0.275rem 0.75rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    border: 0px none;
    background-color: #fff;
    cursor: pointer;
    color: #00aeef;
}
.content-manager section .cm-menu .custom-file {
    height: auto;
    cursor: pointer;
}
.content-manager section .cm-menu .custom-file-input {
    height: calc(1.75rem + 2px);
    cursor: pointer;
    width: 140px;
}
.content-manager section .cm-menu .custom-file-label:after {
    display: none;
}
.content-manager section .cm-hr {
    width: 100%;
    height: 1px;
    border-top: 1px dashed #222;
    position: absolute;
    bottom: -1px;
    margin: 0px;
    background-color: transparent;
    z-index: 1;
}
.content-manager section .cm-dropdown {
    position: relative;
}

.content-manager section .cm-dropdown .section-menu {
    position: fixed;
    left: 0px;
    top: 0px;
    padding: 0px;
    margin: 0px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    min-width: 141px;
    z-index: 50;
    height: 100%;
    width: 230px;
    transition: left 0.3s ease-in-out;
}
.content-manager section .cm-dropdown.closed .section-menu {
    left: -230px;
}
.content-manager section .cm-dropdown .section-menu select:not(.thin),
.content-manager section .cm-dropdown .section-menu .input-group {
    width: 100%;
}
.content-manager section .cm-dropdown ul {
    padding: 0px;
    margin: 0px;
    list-style-type: none;
    width: 100%;
}
.content-manager section .cm-dropdown ul li {
    padding: 8px 12px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
    border-right: 1px solid #eee;
    color: #333;
}
.content-manager section .cm-dropdown ul li:last-child {
    border-bottom: 0px none;
}
.content-manager section .cm-dropdown ul li:hover {
    color: #fff;
    background-color: #00aeef;
}
.content-manager section .cm-headline {
    position: relative;
}
.content-manager section .cm-columns,
.content-manager section .cm-column,
.content-manager section .cm-col-content {
    min-height: 200px;
    position: relative;
}
.content-manager section .cm-col-content {
    min-width: 100%;
}
.content-manager section .cm-col-content.blue {
    background-color: #2a4df4;
    color: #fff;
}
.content-manager section .cm-col-content.blue strong,
.content-manager section .cm-col-content.blue a,
.content-manager section .cm-col-content.blue i {
    color: #fff;
}
.content-manager section .cm-columns .cm-menu.cm-col {
    right: 17px;
}
.content-manager section .cm-columns .cm-column .cm-menu.cm-col {
    right: 17px;
}
.content-manager section .cm-column .cm-col-wrapper {
    min-width: 100%;
}
.content-manager section .cm-banner-content .title.edit,
.content-manager section .cm-banner-content .text.edit,
.content-manager section .cm-actuality-wrapper .title.edit,
.content-manager section .cm-actuality-wrapper .link.edit,
.content-manager section .cm-newsletter-content .title.edit,
.content-manager section .cm-work-in-numbers-content .title.edit,
.content-manager section .cm-work-in-numbers-content .text.edit,
.content-manager section .cm-work-in-numbers-content .link.edit,
.content-manager section .cm-work-in-numbers-content .numbers .number-text.edit,
.content-manager section .cm-work-in-numbers-content .numbers .number.edit,
.content-manager section .cm-zpravodaj-content .content .title.edit,
.content-manager section .cm-zpravodaj-content .content .sub-title.edit .editable,
.content-manager section .cm-social-networks-content .title.edit,
.content-manager section .cm-social-networks-content .title.podcast-block-title,
.content-manager section .cm-social-networks-content .title.podcast-block-text {
    border: 1px solid #ddd !important;
}
.content-manager section .cm-banner-content .title.edit p,
.content-manager section .cm-banner-content .text.edit p,
.content-manager section .btn p,
.content-manager section .info-bordered-block-item .title.edit p,
.content-manager section .info-bordered-block-item .text.edit p,
.content-manager section .info-bordered-block-item .link.edit p,
.content-manager section .cm-newsletter-content .title.edit p,
.content-manager section .cm-newsletter-content .button p,
.content-manager section .cm-work-in-numbers-content .link p,
.content-manager section .cm-zpravodaj-content .sub-title .editable p,
.content-manager section .cm-zpravodaj-content .content button p,
.content-manager section .cm-social-networks-content .title p,
.content-manager section .cm-social-networks-content .podcast-block-title p,
.content-manager section .cm-social-networks-content .podcast-block-text p {
    margin: 0rem;
}

.content-manager section .cm-row,
.content-manager section .cm-col-content,
.content-manager section .cm-headline-content,
.content-manager section .cm-text-content,
.content-manager section .cm-elm-content,
.content-manager section .cm-banner-content {
    position: relative;
}

.content-manager section .cm-banner-content .cm-banner-img-list {
    max-height: 500px;
    width: 600px;
    border: 1px solid #ddd;
    padding: 10px;
    overflow-x: scroll;
}
.content-manager section .cm-banner-content .cm-banner-img-list .cm-banner-img {
    position: relative;
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.2);
}
.content-manager section .cm-banner-content .cm-banner-img-list .cm-banner-img img {
    height: 150px;
    width: auto;
}
.content-manager section .cm-banner-content .cm-banner-img-list .cm-banner-img .cm-banner-img-menu {
    display: none;
    width: 30px;
    height: 30px;
    background-color: #fff;
    position: absolute;
    bottom: 0px;
    right: 0px;
    background-color: rgba(255, 255, 255, 0.8);
}
.content-manager section .cm-banner-content .cm-banner-img-list .cm-banner-img:hover .cm-banner-img-menu {
    display: flex;
}
.content-manager section .cm-banner-content .cm-banner-img-list .cm-banner-img .cm-banner-img-menu .icon {
    height: 25px;
    width: 25px;
}
.content-manager section .cm-border {
    position: absolute;
    z-index: 1;
    left: 0px;
    bottom: 0px;
    top: 0px;
    right: 0px;
    border: 1px solid #ddd;
    pointer-events: none;
}
.content-manager section .cm-col-border {
    position: absolute;
    z-index: 1;
    left: 0px;
    bottom: 0px;
    top: 0px;
    right: 0px;
    border: 1px solid #ddd;
    pointer-events: none;
}

.content-manager section .cm-border.cm-selected {
    border-color: #0d6efd;
    left: 0px;
    bottom: 0px;
    top: 0px;
    right: 0px;
}

.content-manager section .cm-col-border.cm-selected {
    border-color: #0d6efd;
    left: 1px;
    bottom: 1px;
    top: 1px;
    right: 0px;
}
.content-manager section .cm-start-info {
    width: 100%;
    display: flex;
    height: 240px;
    align-items: center;
    justify-content: center;
    color: #bbb;
}

.content-manager section .cm-closing-overlay {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 1;
}

.content-manager section .cm-newsletter-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f0f3fe;
    padding: 2rem 2.5rem;
    margin-top: 5rem;
    margin-bottom: 5rem;
}
.content-manager section .cm-newsletter-content .title {
    font-family: "Right Grotesk", Arial, Helvetica, sans-serif;
    font-size: 1.875rem;
    line-height: 1.2;
    font-weight: 500;
    font-style: normal;
    padding: 0;
    margin: 0;
    position: relative;
    color: #2a4df4;
}
.content-manager section .cm-newsletter-content button {
    font-family: "Right Grotesk", Arial, Helvetica, sans-serif;
    display: inline-block;
    cursor: default;
    border-radius: 0.4rem;
    border: 1px solid #2a4df4;
    background-color: #2a4df4;
    color: #ffffff;
    font-size: 1.125rem;
    line-height: 1.2;
    font-weight: 500;
    text-decoration: none !important;
    margin: 0;
    padding: 0.4875rem 1rem 0.565rem;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.content-manager section .cm-newsletter-content button i {
    margin-right: 0.66rem;
    font-size: 1.25rem;
}

.content-manager section .cm-work-in-numbers-content {
    display: flex;
    justify-content: space-between;
    margin-top: 5rem;
    margin-bottom: 5rem;
}
.content-manager section .cm-work-in-numbers-content .content {
    width: 24.5rem;
    background-color: #f6f6f6;
    padding: 0 5rem 2.5rem 2.5rem;
}
.content-manager section .cm-work-in-numbers-content .content .title {
    font-family: "Right Grotesk", Arial, Helvetica, sans-serif;
    font-size: 3rem;
    line-height: 1.08;
    font-weight: 500;
    font-style: normal;
    padding: 0;
    margin: 2.5rem 0 1.5rem;
    position: relative;
    color: #2a4df4;
}
.content-manager section .cm-work-in-numbers-content .content .text {
    font-size: 1.125rem;
    line-height: 1.5;
    margin: 1.5rem 0;
}
.content-manager section .cm-work-in-numbers-content .content .link {
    display: flex;
    align-items: center;
}
.content-manager section .cm-work-in-numbers-content .content .link .link-icon {
    margin-right: 0.5rem;
    font-size: 1.25rem;
    color: #2a4df4;
}
.content-manager section .cm-work-in-numbers-content .content .link .link-text {
    padding: 0;
    color: #000000;
    font-size: 1.25rem;
    text-decoration: underline !important;
    font-family: "Right Grotesk", Arial, Helvetica, sans-serif;
    flex-grow: 1;
}
.content-manager section .cm-work-in-numbers-content .numbers {
    display: grid;
    width: 43.75rem;
    grid-column-gap: 0rem;
    grid-row-gap: 0rem;
    grid-template-columns: repeat(2, 1fr);
}
.content-manager section .cm-work-in-numbers-content .numbers .item {
    padding: 2.5rem;
    border-right: 1px solid #e2e2e2;
    border-bottom: 1px solid #e2e2e2;
}
.content-manager section .cm-work-in-numbers-content .numbers .item .number {
    display: block;
    font-family: "Right Grotesk", Arial, Helvetica, sans-serif;
    font-size: 5rem;
    font-weight: 500;
    line-height: 1;
    color: #2a4df4;
}
.content-manager section .cm-work-in-numbers-content .numbers .item .number-text {
    margin: 1rem 0 0;
    font-size: 1.125rem;
    color: #5f5f5f;
}

.content-manager section .cm-zpravodaj-content {
    display: flex;
    justify-content: space-between;
    margin-top: 5rem;
    margin-bottom: 5rem;
    min-height: 27rem;
}

.content-manager section .cm-zpravodaj-content .gallery {
    background-color: #f6f6f6;
    width: 37.5rem;
    display: flex;
    overflow-x: auto;
    padding: 2.5rem 0 2.5rem 2.5rem;
    scrollbar-width: 0.5rem;
    scrollbar-color: #2a4df4 #e2e2e2;
}

.content-manager section .cm-zpravodaj-content .gallery.one {
    overflow: hidden;
    align-items: stretch;
    justify-content: center;
    padding-left: 0rem;
    position: relative;
}
.content-manager section .cm-zpravodaj-content .gallery::-webkit-scrollbar {
    width: 0.5rem;
}
.content-manager section .cm-zpravodaj-content .gallery::-webkit-scrollbar-thumb {
    background-color: #2a4df4;
    width: 1rem;
    box-shadow: none;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0.5rem;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOCA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cmVjdCB3aWR0aD0iMiIgaGVpZ2h0PSI4IiBmaWxsPSIjRjBGM0ZFIi8+CjxyZWN0IHg9IjYiIHdpZHRoPSIyIiBoZWlnaHQ9IjgiIGZpbGw9IiNGMEYzRkUiLz4KPC9zdmc+Cg==);
}
.content-manager section .cm-zpravodaj-content .gallery .preview {
    width: 100%;
    height: auto;
    position: relative;
    flex-shrink: 0;
    margin-right: 1.5rem;
}
.content-manager section .cm-zpravodaj-content .gallery.one .preview {
    margin-right: 0rem;
    position: relative;
    flex-grow: 1;
}
.content-manager section .cm-zpravodaj-content .content {
    width: 31rem;
    margin-left: 5rem;
}
.content-manager section .cm-zpravodaj-content .content .title {
    margin-top: 0;
    margin-bottom: 2rem;

    font-size: 3rem;
    line-height: 1.08;
    font-weight: 500;
    font-style: normal;
    padding: 0;
    position: relative;
    color: #2a4df4;
    font-family: "Right Grotesk", Arial, Helvetica, sans-serif;
}
.content-manager section .cm-zpravodaj-content .content .sub-title {
    color: var(--black);
    text-decoration: underline;
    font-size: 1.875rem;
    line-height: 1.2;
    font-weight: 500;
    font-style: normal;
    padding: 0;
    margin: 1.5rem 0 1.5rem;
    position: relative;
    font-family: "Right Grotesk", Arial, Helvetica, sans-serif;

    display: flex;
    align-items: center;
}
.content-manager section .cm-zpravodaj-content .content .sub-title .editable {
    min-width: 20px;
}
.content-manager section .cm-zpravodaj-content .content ul {
    position: relative;
    padding: 0;
    margin: 0;
}
.content-manager section .cm-zpravodaj-content .content ul li {
    list-style: none;
    margin: 0.5rem 0;
    padding: 0 0 0 0.875rem;
    position: relative;
}
.content-manager section .cm-zpravodaj-content .content ul li::before {
    content: "";
    width: 0.375rem;
    height: 0.375rem;
    display: block;
    background-color: #2a4df4;
    position: absolute;
    left: 0;
    top: 0.5625rem;
}
.content-manager section .cm-zpravodaj-content .content .buttons {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}
.content-manager section .cm-zpravodaj-content .content .buttons button {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;

    font-family: "Right Grotesk", Arial, Helvetica, sans-serif;
    display: inline-block;
    cursor: default;
    border-radius: 0.4rem;
    border: 1px solid #2a4df4;
    background-color: #2a4df4;
    color: #ffffff;
    font-size: 1.125rem;
    line-height: 1.2;
    font-weight: 500;
    text-decoration: none !important;
    padding: 0.4875rem 1rem 0.5625rem;
    text-align: left;
}
.content-manager section .cm-zpravodaj-content .content .buttons button.button-archive {
    background-color: #ffffff;
    color: #2a4df4;
}

.content-manager section .cm-social-networks-content .title {
    font-size: 3rem;
    line-height: 1.08;
    font-weight: 500;
    font-style: normal;
    padding: 0;
    margin: 2.5rem 0 1.5rem;
    position: relative;
    color: #2a4df4;
    font-family: "Right Grotesk", Arial, Helvetica, sans-serif;
}

.content-manager section .cm-social-networks-content .x-embed {
    margin-bottom: 2.5rem;
}
.content-manager section .cm-social-networks-content .social-podcasts {
    background-color: #f0f3fe;
    padding: 2.5rem 2rem;
}
.content-manager section .cm-social-networks-content .social-podcasts .podcast-icon {
    margin-right: 1rem;
    margin-top: 0.2rem;
}
.content-manager section .cm-social-networks-content .social-podcasts .podcast-logo {
    margin-left: auto;
    margin-top: 1rem;
}

.content-manager section .cm-social-networks-content .social-podcasts .podcast-block-title {
    color: #000;
    font-family: "Right Grotesk", Arial, Helvetica, sans-serif;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
}
.content-manager section .cm-social-networks-content .social-podcasts .podcast-block-text {
    color: #000;
    font-family: "Helvetica", Arial, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}
.content-manager section .cm-social-networks-content .columns {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    margin: 1rem 0 0 0;
    gap: 3rem;
}
.content-manager section .cm-social-networks-content .columns .column {
    width: 100%;
    position: relative;
}
.content-manager section .cm-social-networks-content .columns .column nav {
    display: flex;
    flex-flow: row wrap;
}
.content-manager section .cm-social-networks-content .columns .column nav a {
    display: flex;
    align-items: center;
    font-family: "Right Grotesk", Arial, Helvetica, sans-serif;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.2;
    margin-right: 1rem;
    text-decoration: underline;
    color: #000000;
}
.content-manager section .cm-social-networks-content .columns .column nav a i {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 50%;
    background-color: #2a4df4;
    font-size: 1.125rem;
    color: #ffffff;
    margin: 0 0.5rem 0 0;
    text-align: center;
}
.content-manager section .cm-social-networks-content .columns .column nav a .x-social-icon img {
    width: 24px;
    height: 24px !important;
    margin: 0 0.5rem 0 0;
}
.content-manager section .cm-social-networks-content .columns .column nav a .youtube-music-icon {
    background-color: #2a4df4;
    border-radius: 100%;
    width: 24px !important;
    height: 24px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
}
.content-manager section .cm-social-networks-content .columns .column nav a .youtube-music-icon img {
    width: 16px;
    height: 16px !important;
    margin: 0;
}
.content-manager section .cm-content-padding {
    padding: 50px;
}
.content-manager .custom-file-label {
    overflow: hidden;
}
.content-manager .custom-file-label::after {
    color: #ffffff;
    content: "Vybrat";
    background-color: #8dc63f;
}
.content-manager .custom-file-input {
    cursor: pointer;
}
.content-manager .h-100 {
    height: 100%;
}
.content-manager :focus {
    outline: none;
}
.content-manager section .cm-paragraph {
    display: inline-block;
    width: 100%;
}
.content-manager .cursor-move {
    cursor: grab;
}
.content-manager .hidden-input {
    display: none;
}
