@font-face {
    font-family: "Blimone";
    font-style: normal;
    font-weight: normal;
    src: url("../Fonts/Blimone_Regular_Inktrap.otf") format("opentype");
}
@font-face {
    font-family: "Blimone";
    font-style: normal;
    font-weight: bold;
    src: url("../Fonts/Blimone_Bold_Inktrap.otf") format("opentype");
}
@font-face {
    font-family: "Chivo";
    font-style: normal;
    font-weight: normal;
    src: url("../Fonts/Chivo-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "Chivo";
    font-style: normal;
    font-weight: bold;
    src: url("../Fonts/Chivo-Bold.ttf") format("truetype");
}

.content-manager {
    background-color: #ebf0ff;
}

.content-manager section {
    padding: 120px 0px;
}
.content-manager section.blue {
    background: linear-gradient(90deg, #0029ff 0%, #0066ff 100%);
}
.content-manager section.circle {
    background-image: url("../Images/ContentManager/circle.png");
    background-repeat: no-repeat;
    background-position: calc(50% - 290px) -380px;
    background-size: initial;
}

.content-manager .cm-banner-content .title {
    display: flex;
    flex-direction: column;
    margin: 30px 0px 48px 0px;
}
.content-manager .cm-banner-content .title * {
    font-family: "Blimone";
    color: #fff;
}
.content-manager .cm-banner-content .title span {
    line-height: 80%;
}
.content-manager .cm-banner-content .title span {
    line-height: 80%;
}
.content-manager .cm-banner-content .text {
    font-size: 16px;
    line-height: 32px;
    color: #fff;
    margin-bottom: 30px;
}
.content-manager .cm-tournaments,
.content-manager .cm-ladders,
.content-manager .cm-athletes,
.content-manager .cm-federations,
.content-manager .cm-clubs,
.content-manager .cm-sports {
    background-color: #fff;
    padding: 100px 40px;
    margin-bottom: 50px;
}
.content-manager .cm-tournaments .title,
.content-manager .cm-ladders .title,
.content-manager .cm-athletes .title,
.content-manager .cm-federations .title,
.content-manager .cm-clubs .title,
.content-manager .cm-sports .title {
    font-size: 30px;
    font-weight: bold;
}
.content-manager .cm-tournaments .info,
.content-manager .cm-ladders .info,
.content-manager .cm-athletes .info,
.content-manager .cm-federations .info,
.content-manager .cm-clubs .info,
.content-manager .cm-sports .info {
    font-size: 15px;
}
.content-manager .btn-white {
    background-color: #fff !important;
    color: #0029ff !important;
    margin-top: 30px;
}

.content-manager .btn .arrow {
    margin-left: 20px;
}

.content-manager .cm-banner-content .slider-desc-col {
    flex: 0 0 calc(50% - 105px);
    max-width: calc(50% - 105px);
    padding: 0;
    display: flex;
    flex-direction: row-reverse;
}
.content-manager .cm-banner-content .slider-description {
    width: 100%;
    height: 670px;
    padding: 0 20px;
    background: linear-gradient(90deg, #0029ff 0%, #0066ff 100%);
}
.content-manager .cm-button-content .btn.white {
    background-color: #fff !important;
    color: #0029ff !important;
}
.content-manager .cm-button-content .btn.blue {
    background: linear-gradient(90deg, #0029ff 0%, #0066ff 100%);
    color: #fff;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
}

.cm-text-content h1,
.cm-text-content h2,
.cm-text-content h3,
.cm-text-content h4,
.cm-text-content h5 {
    line-height: 120%;
    margin: 0px 0px 24px 0px;
    font-family: "Blimone";
    font-weight: bold;
}
.cm-text-content h1 {
    font-size: 64px;
}
.cm-text-content h2 {
    font-size: 40px;
}
.cm-text-content h3 {
    font-size: 32px;
}
.cm-text-content h4 {
    font-size: 26rpx;
}
